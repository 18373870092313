@import url('./_fonts.css');
@import url('./_packages.css');
@import url('./_hero-slider.css');
@import url('./_animations.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        @apply text-white font-primary;
    }

    body {
        @apply bg-primary-bg overflow-x-hidden;
    }

    footer h3 {
        @apply font-semibold text-xl my-6;
    }

    footer li {
        @apply text-sm cursor-pointer mt-3;
    }
}

@layer components {
    .navlink {
        @apply mr-5 flex px-6 py-2 max-w-[200px];
    }

    .clickable {
        @apply cursor-pointer;
    }
}

body {
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 15px;
    background-color: aqua;
}

body::-webkit-scrollbar-track {
    background-color: white;
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(33, 43, 86);
}

.text-news-text {
    position: relative;
}

.text-news-text::before {
    width: 100%;
    height: 100%;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border-radius: 0 0 1rem 1rem;
    opacity: 0.5;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

.news-text-clamp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}