.carousel .slide .legend{
    background: none !important;
}
.slidercontent{
    font-size: 24px !important;
    opacity: 1 !important;
}
.carousel-root{
    width: 100% !important;
}
.carousel.carousel-slider .control-arrow{
    top: 90.5% !important;
    padding: 0px ;
 
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
opacity: 1 !important;
}
.carousel{
    /* position: relative !important;
    top: 150% !important; */
    display: flex ;
    flex-direction: column-reverse;
     
 }
 .carousel.carousel-slider {
  padding: 50px 0px !important;  
 }
 .carousel .carousel-slider {
    display: none !important;
 }
 .carousel .carousel-status{
    display: none !important;
 }
.carousel .thumbs{
    display: none !important;
}
.carousel .thumbs-wrapper{
    display: none;
}
.carousel:hover .slide .legend {
    opacity: 1 !important;
    padding-top: 5px;
    font-size: 1.5vw !important;
    transition: none !important;
}
.legend{
    font-size: 1.5vw !important;
}
.carousel:hover  .animated{
    opacity: 1 !important;
}
.carousel .control-dots .dot{
    padding: 0px 20px;
    border-radius: 0 !important;
}

