.searched-item {
    animation: searched-item-animation 0.2s ease-in-out forwards;
}

@keyframes searched-item-animation {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}